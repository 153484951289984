:root {
    --custom-white-focus: rgba(255, 255, 255, 0.964);
    --custom-white-unfocus: rgba(255, 255, 255, 0.823);
    --custom-grey: grey;
    --cormorant: 'Cormorant Garamond';
    --poppins: 'Poppins', sans-serif;
    --quicksand: 'Quicksand', sans-serif;
}


li {
    list-style: none;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
  }


  ::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 50px;
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }