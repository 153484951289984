.transition { 
    transition: 1s ease-in-out;
}

.container-project {
    background-color: var(--custom-white-unfocus);
    color: rgb(24, 24, 24);
    position: relative;
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.3);
    height: 150px;
    left: 0;
    overflow: hidden;
    right: 0;
    top: 0;
    margin: 0 15px 15px 15px;
    border-radius: 20px;
    padding: 10px 30px 20px;
}

.container-project::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    left:0;
    height: 5px;
    background-color: #FF3CAC;
    background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
    display: flex;
    align-items: center;
    justify-content: center;

}

.container-project:hover {
    height: 320px;
    background-color: var(--custom-white-focus);
    padding-top: 0px;
}

.container-project .info {
    opacity: 0;
}

.container-project:hover .info {
    opacity: 1;
    transition-delay: .5s
}

.container-project .header {
    text-align: left;
    padding: 30px 10px 10px 30px;
    display: grid;
    grid-template-columns: 90% 10%;
    gap: 5px;
}

.links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 3px;
}

.container-project .links a {
    opacity: 0.7;
}

.container-project .links a:hover {
    opacity: 1;
}

.links img {
    height: 20px;
    border-radius: 7px
}

.link img:hover {
    opacity: 1;
}

.info p {
    padding: 0 20px 3px 30px;
    font-size: 0.9rem;
}



@media only screen and (max-width: 850px) {
    .container-projectlist ul {
        grid-template-columns: repeat(1, 1fr);
    }

    .container-project {
        height: 320px;      
        background-color: rgba(255, 255, 255, 0.942);  
    }

    .container-project .info {
        opacity: 1;
    }
}