.container-menu {
    display: flex;
    justify-content: center;
    padding: 20px;
    height: 100px;
}

.container-menu ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
    background-color: var(--custom-white-unfocus);
    border-radius: 100px;
    padding: 10px 0px 5px 0px;
    width: 300px;
    transition: .5s ease-in-out;
}

.container-menu ul:hover {
    background-color: var(--custom-white-focus);
}

.container-menu img {
    height: 25px;
    z-index: 99;
}

.container-menu li  {
   position: relative;
   list-style: none;
}

/* 
.container-menu li .active {
    position: absolute;
    content: "";
    left: -3px;
    right: -3px;
    bottom: 0;
    height: 6px;
    background-color: rgb(28, 28, 28);
    opacity: 0;
    transition: 0.5s ease-in-out;
    border-radius: 10px;
    filter: blur(3px);
    opacity: 1;
} 
*/

.container-menu li::after {
    position: absolute;
    content: "";
    left: -3px;
    right: -3px;
    bottom: 0;
    height: 6px;
    background-color: rgb(28, 28, 28);
    opacity: 0;
    transition: 0.5s ease-in-out;
    border-radius: 10px;
    filter: blur(3px);

}

.container-menu li:hover::after {
    opacity: 1;
}

.container-menu .dev {
    border-radius: 100px;
}

@media only screen and (max-width: 850px) {
    .container-menu {
        position: sticky;
        bottom: 0;
    }

    .container-menu ul {
        background-color: rgb(227, 227, 227);
        border: 1px solid rgb(62, 62, 62);
    }
}