.container-projectlist {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-projectlist ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 300px;
    min-height: 250px;
    justify-content: center;
    align-items: center;
}

.container-projectlist ul li {
    width: 400px;
}

@media only screen and (max-width: 1220px) {
    .container-projectlist ul {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 300px 300px;
    }
}

@media only screen and (max-width: 850px) {
    .container-projectlist ul {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 320px;
    }
}