.container-datenschutz {
    color: var(--custom-white-focus);
    padding: 0 20%;
}

.container-datenschutz h1 {
    text-align: center;
    padding-bottom: 40px;
}

.container-datenschutz * {
    padding: 15px;
}

.container-datenschutz .legal {
    padding-top: 100px;
}

.container-datenschutz a {
    color: rgb(76, 76, 245);
    cursor:pointer;
}

@media only screen and (max-width: 700px) {
    .container-datenschutz {
        padding: 0 10%;
    }
}
