.container-cv {
    min-height: 250px; 
    padding: 0 20% 0 20%;
    position: relative;
}

.container-cv .contact {
    display: flex;
    color: black;
    z-index: 99;
    text-decoration: none;
}

.container-cv .download {
    display: flex; 
    flex-direction: column;
    position: absolute;
    top: .5%;
    right: 20.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-cv .download button {
    font-size: .8rem;
    padding: 10px 20px 10px 20px;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border-radius: 20px;
    border: solid 3px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #78e4ff, #ff48fa);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;
}

.container-cv .download button:hover {
    box-shadow: none;
    color: white;
  }

  .container-cv .download p {
      font-size: .6rem;
  }

.container-cv .buttons > * {
   padding: 8px 16px;
   margin: 5px 5px 0 0;
} 

.container-cv .cv {
    background-color: rgba(255, 255, 255, 0.889);
    padding: 30px;
    border-radius: 20px;
}

.container-cv h4 {
    font-size: 1.5rem;
}

.container-cv .ls-head {
    padding-top: 20px;
    padding-bottom: 5px;
}

.container-cv .education-head {
    padding-top: 20px;
    padding-bottom: 5px;
}

.container-cv .skills-head {
    padding-top: 20px;
    padding-bottom: 5px;
}

.container-cv h5 {
    font-size: 1.1rem;
    padding-top: 10px;
}

.container-cv .head ul p {
    padding-top: 10px;
}

.container-cv .dates {
    padding-top: 10px;
}

.container-cv .head {
    display: flex;
    justify-content: space-between;
}

.container-cv .cv li li {
    list-style: outside;
    list-style-type: circle;
    margin-left: 20px;
}


.container-cv .leadership li, .container-cv .skills li, .education li {
    list-style: outside;
    list-style-type: circle;
    margin-left: 15px;
}


@media only screen  and (max-width: 920px) {
    .container-cv {
        padding: 0 1% 0 1%;
    }

    .container-cv .head {
        flex-direction: column;
    }

    .container-cv .ls-head {
        padding-bottom: 0;
    }

    .container-cv .dates {
        padding-bottom: 5px;
        padding-top: 0px;
    }

    .container-cv .download {
        right: 1.5%;
    }
}


@media only screen  and (max-width: 700px) {
    .container-cv .dates {
        padding-bottom: 5px;
        padding-top: 0px;
    }

    .container-cv .download button {
        font-size: .7rem;
        padding: 6px 12px 6px 12px;
    }

    .container-cv .download button p {
        font-size: .5rem;
    }
}