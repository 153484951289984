
.container-footer {
    text-align: right;
    font-size: 0.8rem;
    position: relative;
    top: -3px;
    right: 10px;
}

.container-footer a {
    text-decoration: none;
    color: grey;
}

.container-footer li {
    list-style: none;
}