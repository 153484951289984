.container-about {
    min-height: 250px;
    color:white;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.container-about > * {
    padding: 30px;
}

.container-about h3 {
    align-self: flex-start;

}

