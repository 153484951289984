.container-landing {
    padding-top: 180px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: columN;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--custom-white-unfocus);
    padding-bottom: 30px;
    height: 30%;
    min-height: 280px;
}

.container-landing h1 {
    font-size: 1.4rem;
    font-weight: bold;   
    letter-spacing: 1px;
    position: relative;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--quicksand);
    padding-bottom: 20px;
    text-transform: uppercase;
}

.container-landing h1 a {
    text-decoration: none;
    color: var(--custom-white-unfocus);
}

.container-landing ul {
    font-family: sans-serif;
    position: relative;
    width: 40%;
    padding: 0 0 20px 0;
    font-size: .8rem;
    font-weight: 100;
    color: rgb(170, 170, 170);
    display: flex;
    justify-content: center;
}

.container-landing ul li {
    text-decoration: none;
    color: rgb(170, 170, 170);
    padding: 0 10px 0 10px;
    text-align: center;
}

.container-landing a {
    text-decoration: none;
    color: rgb(170, 170, 170);
}

.container-landing ul li:hover, .container-landing .trainer:hover {
    color: var(--custom-white-unfocus);
    transition: .3s ease-in-out;
    text-decoration: underline;
}

.container-landing ul::after, .container-landing .trainer::after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: #FF3CAC;
    background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);   
}


@media only screen and (max-width: 1100px) {
    .container-landing ul {
        width: 60%;
    }
}

@media only screen and (max-width: 850px) {
    .container-landing ul {
        width: 80%;
    }
    .container-landing {
        padding-top: 50px;
        min-height: 200px;
    }
}


@media only screen and (max-width: 600px) {
    .container-landing h2 {
        width: 95%;
    }
}